.admin {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;

  &-userbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 60px;
    min-width: 250px;
    border-radius: 10px;
    padding: 5px 10px;
    background: transparent;

    &-avatar {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      user-select: none;
    }

    &-ctn {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-name {
      font-weight: bold;
    }

    &-role, &-id {
      font-size: 0.8rem;
      color: gray;
    }
  }
}

.appeal {

  font-family: sans-serif !important;

  &-school,
  &-categories,
  &-user,
  &-actions {
    h4 {
      background: rgb(244, 246, 248);
      padding: 1rem;
      margin: 20px 0;
    }

    p {
      display: flex;
      align-items: center;
      padding: 10px 1rem;
    }
  }

  &-categories-ctn {
    padding: 5px 1.5rem;
  }

  .appeal-category-label {
    & .Mui-checked {
      color: #26617D !important;
    }

    & span {
      color: black !important;
      margin-left: -5px;
    }
  }

  &-label {
    width: 50%;
    display: inline-block;
    font-weight: bold;
    margin-right: 10%;
  }

  &-value {
    width: 40%;
    display: inline-block;
  }

  & .MuiDivider-root {
    margin: 0 0.8rem;
  }

  &-actions {
    &-decline {
      background: #d72626 !important;
    }

    &-accept {
      margin-left: auto !important;
    }
  }
}

@media screen and (max-width: 900px) {
  .appeal {
    font-size: 11px;
  }
}

