.auth {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;

  height: 100vh;

  &-header {
    margin: 0 0 2rem 0;

    &-link {
      display: inline-block;
      margin: 1rem 0 0 0;
    }
  }

  h1 {
    font-size: 2rem;
    font-family: sans-serif;
    text-align: center;
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    gap: 10px;

    height: 10vh;
    margin: 0 0 2rem 0;

    & .input-holder {
      display: flex;
      align-items: center;
      width: 350px;
      height: 50px;
      padding: 0 0 0 1rem;

      & .MuiInputBase-root {
        flex: 1;
      }
    }
  }

  &-header-link,
  &-middle-link {
    color: #1976d2 !important;
    text-decoration: none;
    transition: color 0.5s;
  }

  &-header-link:hover,
  &-middle-link:hover {
    color: darkblue !important;
  }

  h2 {
    font-size: 1.5rem;
    font-family: sans-serif;
    text-align: center;
  }

  .auth-input-btn {
    font-family: sans-serif;
    font-size: 0.8rem;
    border-radius: 100px;
  }

  .auth-input-btn:hover {
    color: darkblue !important;
    background: unset !important;
  }
}