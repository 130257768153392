.register {
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  font-family: sans-serif;

  &-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;

    &-box {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    & .input-holder {
      display: flex;
      align-items: center;
      width: 400px;
      height: 50px;
      padding: 0 0 0 1rem;

      & .MuiInputBase-root {
        flex: 1;
      }
    }
  }

  & .register-btn {
    font-family: sans-serif;
    width: 250px;
    margin: 20px 0 0 0;
    align-self: center;
  }

  &-checkbox {
    width: 50px;
  }

  &-file {
    font-family: sans-serif;
  }
}

.category-label {
  & .MuiTypography-root {
    width: 2rem;
    margin-left: -10px;
  }
}