@import "./global/assets/fonts/Druk/DrukText.css";

html,
body,
#root {
  min-height: 100vh;
  height: auto;
}

body {
  background: #fff;
}

.App {
  background: linear-gradient(111.61deg, #C6D2E5 5%, #D6E3EF 23%, #EBEAE6 47%, #F5F5F3 95%);
  min-height: 100vh;
}

.main-page {
  background: #F4F7FE;
  min-height: 100vh;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.MuiDataGrid-root {
  font-family: sans-serif !important;
  padding: 14px;
  border-radius: 14px !important;
  background: #FFFFFF !important;
  font-size: 13px !important;
}

.MuiDataGrid-cell {
  border: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeaders {
  margin: 0 0 10px 0;
  background-color: rgb(244, 246, 248);
  border-radius: 8px;
  border: 0px !important;
  position: relative;
}

.MuiDataGrid-columnHeader {
  border: 1px solid white !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.MuiDataGrid-footerContainer {
  margin: 10px 0 0 0;
}

.MuiDataGrid-row {
  border-radius: 8px;
  cursor: pointer;
}

.navigation-p {
  text-decoration: none;
  font-weight: bold;
}

.navigation-link {
  text-decoration: none;
  color: darkgray;
  font-size: 8px !important;
}

.navigation-link:hover {
  text-decoration: underline;
  color: gray;
}

.navigation-name,
.navigation-iin,
.navigation-school {
  font-size: 12px !important;
}

.navigation-name {
  font-weight: bold !important;
}

.navigation-iin,
.navigation-school {
  font-size: 11px !important;
  color: gray !important;
}

.dataGridRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.video-custom-progress {
  width: 100%;
  height: 10px;
  appearance: none; /* Убираем стандартный стиль */
  border: none;
}

.video-custom-progress::-webkit-progress-bar {
  background-color: #e0e0e0; /* Цвет фона полосы */
  border-radius: 5px;
}

.video-custom-progress::-webkit-progress-value {
  background-color: #ff6a00; /* Цвет заполненной части */
  border-radius: 5px;
}

.video-custom-progress::-moz-progress-bar {
  background-color: #ff6a00; /* Цвет для Firefox */
}

/* ------------------- */
.info-header {
  background: #05304F;
  color: #fff;
  padding: 11px 16px;
  margin-bottom: 8px !important;
  border-radius: 6px !important;
}

.info-row {
  background: #F5F5F5;
  padding: 10px 16px;
  border-radius: 6px !important;
}

.info-categories {
  background: #f5f5f5;
  border-radius: 6px !important;
  padding: 23px;
}

.schools-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Две равные колонки */
  grid-template-rows: auto auto;  /* Две строки */
  align-items: start; /* Выровнять элементы по верху */
  row-gap: 32px;
  column-gap: 16px;
}

.item-1 {
  grid-column: 1;
  grid-row: 1;
}

.item-2 {
  grid-column: 2;
  grid-row: 1;
}

.item-3 {
  grid-column: 1;
  grid-row: 2;
}

.item-4 {
  grid-column: 2;
  grid-row: 2;
}

@media (max-width: 768px) { /* Или любое другое значение ширины */
  .schools-info-grid {
    grid-template-columns: 1fr; /* Одна колонка */
    grid-template-rows: auto; /* Автоматические строки */
  }

  .item-2 {
    grid-column: 1;
    grid-row: 3;
  }

  .item-3 {
    grid-column: 1;
    grid-row: 2;
  }

  .item-4 {
    grid-column: 1;
    grid-row: 4;
  }
}

/*.data-grid-header {*/
/*  background: #1E9AF3;*/
/*  color: #fff;*/
/*}*/

/*.MuiDataGrid-columnHeader {*/
/*  background: #1E9AF3;*/
/*  color: #fff;*/
/*}*/